import React, { useState, useEffect } from "react"
import Snackbar from "@material-ui/core/Snackbar"
import MuiAlert from "@material-ui/lab/Alert"
import styled from "styled-components"
import { theme } from "../layout/theme"

const StyledSnackbar = styled(Snackbar)`
  z-index: 10500;
  .cookieModal {
    background-color: ${theme.palette.primary.main};
    font-family: ${theme.typography.fontFamily[1]};
  }
  .cookiesLink {
    color: white;
    text-decoration: underline;
  }
`

const Alert = props => {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const CookiesModal = props => {
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (typeof window !== "undefined") {
      //Si no estan las cookies en el localStorage, poner alerta
      if (localStorage.getItem("cookies")) {
        setOpen(false)
        return
      } else {
        setOpen(true)
      }
    } else return
  }, [])

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return
    }

    //Si no estan las cookies en el localStorage, poner alerta

    if (typeof window !== "undefined") {
      if (localStorage.getItem("cookies")) {
        setOpen(false)
        return
      } else {
        localStorage.setItem("cookies", "accepted")
        setOpen(false)
      }
    }
  }

  return (
    <StyledSnackbar open={open} autoHideDuration={9000} onClose={handleClose}>
      <Alert onClose={handleClose} severity="info" className="cookieModal">
        En este sitio web usamos cookies propias y de terceros para mejorar tu
        experiencia. Si continúas navegando, entendemos que aceptas nuestra{" "}
        <a
          href="https://auditorio-alfredokraus.com/politica-cookies"
          className="cookiesLink"
        >
          política de cookies.
        </a>
      </Alert>
    </StyledSnackbar>
  )
}

export default CookiesModal
