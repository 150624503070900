import React, { useEffect, useState } from "react"
import Img from "gatsby-image"

//import enigmas
import Switches from "../components/enigmas/switches"
import SolutionForm from "../components/enigmas/solutionForm"
import MatrixBingo from "../components/enigmas/matrixBingo"
import Matrix from "../components/enigmas/matrix"
import OnlyText from "../components/enigmas/onlyText"
import Hints from "../components/enigmas/hints"

const Challenge = props => {
  const data = props.data.node
  const challengeType = props.data.node.acf.challenge_type
  const content = props.data.node.content
  const hints = props.data.node.acf.add_hints

  const [resetNum, setResetNum] = useState()

  useEffect(() => {
    setResetNum(Math.random())
    window.scrollTo(0, 0)
  }, [props])

  return (
    <>
      {props.data.node.acf.public_title ? (
        <h1>{props.data.node.acf.public_title}</h1>
      ) : (
        ""
      )}

      {props.data.node.featured_media ? (
        props.data.node.acf.featured_img_top === true ? (
          <Img
            fluid={
              props.data.node.featured_media.localFile.childImageSharp.fluid
            }
          />
        ) : (
          ""
        )
      ) : (
        ""
      )}

      {<div dangerouslySetInnerHTML={{ __html: content }} />}

      {props.data.node.featured_media ? (
        props.data.node.acf.featured_img_top === false ? (
          <Img
            fluid={
              props.data.node.featured_media.localFile.childImageSharp.fluid
            }
          />
        ) : (
          ""
        )
      ) : (
        ""
      )}

      {props.data.node.acf.are_audio_files === true
        ? props.data.node.acf.audio_files.map(audio => (
            <audio
              src={audio.audio.localFile.publicURL}
              key={audio.audio.localFile.publicURL}
              controls
            />
          ))
        : ""}

      {challengeType === "simple" ? (
        <SolutionForm data={data} next={props.challengeDone} />
      ) : challengeType === "switches" ? (
        <Switches data={data} next={props.challengeDone} />
      ) : challengeType === "matriz rellenable" ? (
        <MatrixBingo data={data} next={props.challengeDone} />
      ) : challengeType === "simple + respuesta" ? (
        <SolutionForm data={data} next={props.challengeDone} />
      ) : challengeType === "matriz" ? (
        <Matrix data={data} next={props.challengeDone} />
      ) : challengeType === "solo texto" ? (
        <OnlyText data={data} next={props.challengeDone} />
      ) : (
        <MatrixBingo data={data} next={props.challengeDone} />
      )}
      {hints === true ? (
        <Hints hints={props.data.node.acf.hints} resetNum={resetNum} />
      ) : (
        ""
      )}
    </>
  )
}

export default Challenge
